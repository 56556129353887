<template>
  <div class="journalism">
    <my-banner :banner="banner" :nav="nav" />
    <router-view />
  </div>
</template>

<script>
import { queryPhotoManageList } from "@/api/api.js";
export default {
  name: "journalism",
  data() {
    return {
      banner: "",
      nav: [
        {
          path: "/journalism/industry",
          name: "行业新闻",
        },
        {
          path: "/journalism/company",
          name: "公司动态",
        },
        {
          path: "/journalism/community",
          name: "小区动态",
        },
      ],
    };
  },
  created() {
    queryPhotoManageList({
      photoPage: "新闻动态",
    }).then((res) => {
      this.banner = res.data.rows[0].photoPicture;
    });
  },
};
</script>
